import styled from 'styled-components';

import { font } from '../../styles/atoms/typography';

export const PanelStyles = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  padding-bottom: 40px;
  align-items: center;
  justify-content: center;
  -webkit-box-align: center;
  margin: 40px auto !important;
  padding-top: 40px;

  .heading {
    max-width: 328px !important;
    white-space: normal;
    padding-bottom: 24px;
  }

  h1 {
    text-align: left;
    font-size: 35.16px;
  }

  .wrapper {
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1119px) {
      flex-direction: column !important;
    }

    .media,
    .text {
      @media (max-width: 375px) {
        padding: 0;
      }
    }

    .media {
      .feature-image {
        @keyframes fadein {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        img {
          position: relative;
          max-width: 670px !important;

          &.image-animation {
            animation: fadein 1s ease-in-out running;
          }

          @media (max-width: 768px) {
            margin-bottom: 24px;
            width: 100%;
          }
        }
      }

      .inactive {
        display: none;
      }

      .active {
        display: block;
      }
    }

    .text {
      max-width: 375px;
      width: 100%;
    }
  }
`;

export const Feature = styled.h6`
  ${font('text', 'lg', 400)}
  text-align: left;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  max-width: 474px;
  min-height: 54px;

  align-self: stretch;
  flex-grow: 0;
  margin: 7px 0px;

  &:hover {
    color: #1c3fff;
    font-weight: 700;
    cursor: pointer;
  }

  &.active {
    color: #1c3fff;
    font-weight: 700;
    background: #ffffff;
    border: 1px solid #f0f2f5;
    box-sizing: border-box;
    box-shadow: 0px 12px 32px rgba(106, 114, 158, 0.15);
    border-radius: 6px;
  }
`;

export const FeatureWrapper = styled.div``;
